async function parseResponse(resp) {
  if (resp.ok) {
    return {
      data: await resp.json(),
      error: null,
    };
  } else {
    const response = {
      data: null,
      error: {
        code: resp.status,
        text: resp.statusText,
        body: await resp.json(),
      },
    };
    if (resp.status === 400) {
      response.error.text += `: ${response.error.body.title}`;
    }
    if (resp.status === 401) {
      response.error.text += `: ${response.error.body.message}`;
    }
    if (response.error.body.detail) {
      response.error.text += `: ${response.error.body.detail}`;
    }
    return response;
  }
}

export async function post(url, postData) {
  const resp = await fetch(url, {
    method: "POST",
    body: JSON.stringify(postData),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return await parseResponse(resp);
}

export async function get(url) {
  const resp = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return await parseResponse(resp);
}
