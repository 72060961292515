import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import OrangeButton from "../../../SharedComponents/OrangeButton.jsx";
import useTitle from "../../../hooks/useTitle.js";
import bg from "../../../Assets/bus_interior_wide.png";
import mainStyles from "../../../Assets/js/mainStyles.js";

const useStyles = makeStyles((theme) => ({
  roleRoot: {
    backgroundImage: `url(${bg})`,
  },
  header: {
    fontWeight: 700,
    fontStyle: "italic",
    margin: "5vh 0 7vh",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  cards: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10vh",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  card: {
    maxWidth: "34%",
    minHeight: 400,
    padding: 10,
    borderRadius: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      minHeight: 300,
      borderRadius: 20,
      maxWidth: "80%",
    },
  },
  carrierCard: {
    marginRight: 10,
  },
  municipalityCard: {
    marginLeft: 10,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginTop: 20,
    },
  },
  cardTitle: {
    fontWeight: 600,
    fontStyle: "italic",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
  },
  cardContent: {
    fontWeight: 500,
    fontStyle: "italic",
    textAlign: "center",
    padding: "0 15px",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
      padding: 0,
    },
  },
  cardActions: {
    justifyContent: "center",
  },
}));

const useStylesMain = makeStyles(mainStyles);

const RoleSelect = () => {
  const classes = useStyles();
  const classesMain = useStylesMain();
  const history = useHistory();

  useTitle("Квиток просто - Выбрать роль");

  return (
    <Box
      className={classes.roleRoot}
      minHeight="calc(100vh - 48px)"
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingBottom="40px"
    >
      <Typography className={classes.header} variant="h4">
        Выберите свою роль в системе
      </Typography>
      <Box className={classes.cards} width="100%">
        <Card className={clsx(classes.card, classes.carrierCard)} elevation={3}>
          <CardHeader
            title={
              <Typography className={classes.cardTitle} variant="h5">
                Перевозчик
              </Typography>
            }
          />
          <CardContent>
            <Typography className={classes.cardContent} variant="body1">
              Короткое описание того что из себя представляет перевозчик и какие у него возможности.
            </Typography>
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button className={clsx(classesMain.button, classesMain.primaryBtn)} onClick={() => history.push("/registration/carrier")}>
              Выбрать
            </Button>
          </CardActions>
        </Card>
        <Card className={clsx(classes.card, classes.municipalityCard)} elevation={3}>
          <CardHeader
            title={
              <Typography className={classes.cardTitle} variant="h5">
                Муниципалитет
              </Typography>
            }
          />
          <CardContent>
            <Typography className={classes.cardContent} variant="body1">
              Короткое описание того что из себя представляет муниципалитет и какие у него возможности.
            </Typography>
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button className={clsx(classesMain.button, classesMain.primaryBtn)} onClick={() => history.push("/registration/municipality")}>
              Выбрать
            </Button>
          </CardActions>
        </Card>
      </Box>
      <OrangeButton className={classesMain.button} onClick={() => history.goBack()}>
        Назад
      </OrangeButton>
    </Box>
  );
};

export default RoleSelect;
