import React from "react";
import { Sidebar } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    zIndex: "unset",
    boxShadow: theme.shadows[4],
    backgroundColor: theme.palette.secondary.main,
  },
}));

const MySidebar = (props) => {
  const classes = useStyles(props);

  return <Sidebar {...props} classes={classes} />;
};

export default MySidebar;
