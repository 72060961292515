import React, { useCallback, useState } from "react";
import {Datagrid, EditButton, List, TextField, useDataProvider, useLocale, useRefresh, useTranslate} from "react-admin";
import TextInput from '@material-ui/core/TextField';
import {makeStyles, Box, Typography} from '@material-ui/core';
import XLSX from "xlsx";
import MyPagination from "../../../../SharedComponents/MyPagination.jsx";
import ListTextFilter from "../../../../SharedComponents/ListTextFilter.jsx";
import {useSnackbar} from "../../../../Providers/Common/snackbarProvider.jsx";
import {dateToString} from "../../../../Utils/DateUtils.js";

const useStyles = makeStyles({
  tableWrapper: {
    overflowX: "auto",
  },
  cashInput: {
    maxWidth: 100,
    overflow: "hidden",
    border: "1px solid #F1F1F1",
    boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
    "& input": {
      padding: "5px 10px",
    }
  },
  primaryBtn: {
    fontSize: 12,
    minWidth: 135,
    "&.Mui-disabled": {
      backgroundColor: "#0085ba52",
      color: "#fff",
    },
  },
  exportBtn: {
    backgroundColor: "#1D9200",
    color: "#fff",
    fontSize: 12,
    "&:hover": {
      backgroundColor: "#196107",
    },
  },
});

const Buttons = (props) => {
  // eslint-disable-next-line react/prop-types
  const { record: { id, closed }, onClick } = props;

  return (
    <Box display="flex">
      <EditButton {...props} label="resources.dispatchers.close" icon={null} variant="contained" onClick={() => onClick(id)} disabled={closed}/>
    </Box>
  );
};

/*eslint-disable react/prop-types*/
export const DateMonthTextField = (props) => {
  const {record} = props;
  return (<Typography>{dateToString(new Date(record.createdAt), "dd.MM.yyyy")}</Typography>);
}

const Checkout = (props) => {
  const [proceeds, setProceeds] = useState(0);
  // eslint-disable-next-line react/prop-types
  const { classes, setProceedsValue, record: { id, revenuePerShift, closed } } = props;
  const inputChange = (value) => {
    setProceeds(value);
    setProceedsValue(value, id);
  }

  return (
    <TextInput
      // eslint-disable-next-line react/prop-types
      className={classes.cashInput}
      type="number"
      onChange={(e) => inputChange(e.target.value)}
      value={revenuePerShift > 0 ? revenuePerShift : proceeds}
      disabled={closed}
    />
  )
}

const exporter = (t, locale) => {
  /** @param {StatisticsItem[]} data */
  const f = function (data) {
    const dataForExport = data.map((item) => {
      //eslint-disable-next-line unused-imports/no-unused-vars
      item.closed = item.closed ? 'Закрыта' : 'Открыта'
      const { id, index, ...itemForExport } = item;
      return itemForExport;
    });
    const headers = Object.keys(dataForExport[0]).map((key) => t(`resources.dispatchers.fields.${key}`));
    const ws = XLSX.utils.aoa_to_sheet([headers]);
    XLSX.utils.sheet_add_json(ws, dataForExport, { origin: "A2", skipHeader: true });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Dispatchers");
    XLSX.writeFile(wb, "Dispatchers.xlsx");
  };
  return f;
};

export const ListDrivers = (props) => {
  const [data, setData] = useState([]);
  const classes = useStyles();
  const { put } = useDataProvider();
  const refresh = useRefresh();
  const snackbar = useSnackbar();
  const t = useTranslate();
  const locale = useLocale();

  const closeHandler = useCallback(async () => {
    let newData = {};
      data.forEach(function(obj) {
      newData = obj;
    });
    try {
      await put(`dispatchers/close-shift/${newData.id}`, {proceeds: newData.proceeds});
      refresh();
    } catch (e) {
      snackbar.error(e.message);
    }
  });

  const handleChange = (value, id) => {
    setData(state => [...state, {proceeds:value, id}]);
  }

  return (
    <List
      {...props}
      exporter={exporter(t, locale)}
      bulkActionButtons={false}
      perPage={25}
      pagination={<MyPagination />}
      filters={<ListTextFilter />}
      className={classes.tableWrapper}
    >
      <Datagrid>
        <TextField source="routeNumber" />
        <TextField source="routeName" />
        <TextField source="vehicleNumber" />
        <TextField source="tailNumber" />
        <TextField source="driverFullName" />
        <TextField source="driverPersonalNumber" />
        <DateMonthTextField source="createdAt"/>
        <TextField source="dayRevenueAmount" />
        <Checkout
          classes={classes}
          label={"resources.dispatchers.fields.revenuePerShift"}
          setProceedsValue={handleChange}
        />
        <Buttons className={classes.primaryBtn} variant="contained" color="primary" size="small" onClick={closeHandler}/>
      </Datagrid>
    </List>
  );
};
