export default {
  ra: {
    action: {
      add_filter: "Додати фільтр",
      add: "Додати",
      back: "Назад",
      bulk_actions: "1 обраний |||| %{smart_count} обрано |||| %{smart_count} обрано",
      cancel: "Скасування",
      clear_input_value: "Очистити",
      clone: "Дублювати",
      confirm: "Підтвердити",
      create: "Створити",
      delete: "Видалити",
      edit: "Змінити",
      export: "Експорт у Excel",
      list: "Список",
      refresh: "Оновити",
      remove_filter: "Прибрати фільтр",
      remove: "Видалити",
      save: "Зберегти",
      search: "Пошук",
      show: "Перегляд",
      sort: "Сортування",
      undo: "Відмінити",
      unselect: "Скасувати вибір",
      expand: "Розкрити",
      close: "Закрити",
      open_menu: "Показати меню",
      close_menu: "Сховати меню",
    },
    boolean: {
      true: "Так",
      false: "Ні",
      null: " ",
    },
    page: {
      create: "Створити %{name}",
      dashboard: "Головна",
      edit: "%{name} #%{id}",
      error: "Щось пішло не так",
      list: "%{name}",
      loading: "Завантаження",
      not_found: "Не знайдено",
      show: "%{name} #%{id}",
      empty: "Ні %{name}.", // not used
      invite: "Хочете додати ще одну?", // not used
    },
    input: {
      file: {
        upload_several: "Перетягніть файли сюди або натисніть щоб обрати.",
        upload_single: "Перетягніть файл сюди або натисніть щоб обрати.",
      },
      image: {
        upload_several: "Перетягніть зображення сюди або натисніть щоб обрати.",
        upload_single: "Перетягніть зображення сюди або натисніть щоб обрати.",
      },
      references: {
        all_missing: "Пов'язаних даних не знайдено",
        many_missing: "Деякі з пов'язаних даних недоступні",
        single_missing: "Пов'язаний об'єкт недоступний",
      },
      password: {
        toggle_visible: "Приховати пароль",
        toggle_hidden: "Показати пароль",
      },
    },
    message: {
      about: "Довідка",
      are_you_sure: "Ви впевнені?",
      bulk_delete_content: "Ви впевнені, що хочете видалити цей об'єкт |||| Ви впевнені, що хочете видалити об'єкти, кількістю %{smart_count} ? |||| Вы уверены, что хотите удалить объекты, кол-вом %{smart_count}?",
      bulk_delete_title: "Видалити об'єкт |||| Видалити %{smart_count} об'єкти |||| Видалити %{smart_count} об'єктів",
      delete_content: "Ви впевнені, що хочете видалити цей об'єкт",
      delete_title: 'Удалить %{name} "%{id}"',
      details: "Опис",
      error: "У процесі запиту виникла помилка. Він не може бути завершений",
      invalid_form: "Форма заповнена з помилками. Будь ласка перевірте",
      loading: "Триває завантаження, будь ласка, зачекайте...",
      no: "Нет",
      not_found: "Помилка URL або помилкове посилання",
      yes: "Да",
      unsaved_changes: "Деякі зміни не були збережені. Ви впевнені, що хочете це ігнорувати?",
    },
    navigation: {
      no_results: "Результатів Не знайдено",
      no_more_results: "Сторінка %{page} виходить за межі нумерації, спробуйте попередню",
      page_out_of_boundaries: "Сторінка %{page} поза межами діапазона",
      page_out_from_end: "Неможливо переміститися далі останньої сторінки",
      page_out_from_begin: "Номер сторінки не може бути менше 1",
      page_range_info: "%{offsetBegin}-%{offsetEnd} з %{total}",
      page_rows_per_page: "Строк на сторінці:",
      next: "Наступна",
      prev: "Попередня",
      skip_nav: "?",
    },
    sort: {
      sort_by: "Сортувати по %{field} %{order}",
      ASC: "зростанню",
      DESC: "зменшенню",
    },
    auth: {
      auth_check_error: "Будь ласка, авторизуйтесь щоб продовжити роботу",
      user_menu: "Профіль",
      username: "Им'я користувача", // not used
      password: "Пароль", // not used
      sign_in: "Увійти", // not used
      sign_in_error: "Помилка аутентифікації, спробуйте ще раз", // not used
      logout: "Вийти",
    },
    notification: {
      updated: "Елемент оновлений |||| %{smart_count} оновлені |||| %{smart_count} оновлено",
      created: "Елемент створений",
      deleted: "Елемент видалений |||| %{smart_count} видалені |||| %{smart_count} видалено",
      bad_item: "Елемент не валиден",
      item_doesnt_exist: "Елемент не існує",
      http_error: "Помилка серверу",
      data_provider_error: "Помилка dataProvider, перевірте консоль",
      i18n_error: "Неможливо завантажити переклад для вказаної мови", // not used
      canceled: "Операція відмінена",
      logged_out: "Вашу сесію завершено, спробуйте увійти знову",
    },
    validation: {
      required: "Обов'язкове для заповнення",
      minLength: "Мінімальна кількість символів %{min}",
      maxLength: "Максимальна кількість символів %{max}",
      minValue: "Мінімальне значення %{min}",
      maxValue: "Значенння може бути %{max} або менше",
      number: "Повинно бути цифрою",
      email: "Некоректна email адреса",
      oneOf: "Має бути одним з: %{options}",
      regex: "Має бути у форматі (regexp): %{pattern}",
    },
  },
  errors: {
    loadData: "Помилка завантаження даних",
    notFound: "Не знайдено",
    downloadFailed: "Помилка завантаження",
    uploadError: "Помилка завантаження: ",
    parsingError: "Помилка розбору даних: ",
    clickToRefresh: "Натисніть, щоб оновити",
    routeNotFound: "Оберіть розділ, в меню з лівої сторони",
  },
  validate: {
    startWhitespace: "Не повинно починатися з пробілу",
    endWhitespace: "Не повинно закінчуватися пробілом",
    url: "Ця url-адреса недоступна",
    html: "Текст не повинен містити код html",
    checked: "Необхідно поставити галочку",
    passwordsEquals: "Паролі повинні співпадати",
  },
  common: {
    created: "Створений",
    updated: "Оновлений",
    autoSaving: "Збереження...",
    autoSaved: "Збережено",
    superAdmin: "Super Admin",
    appName: 'Портал "Квиток Просто"',
    currency: "грн",
  },
  roles: {
    ROLE_SUPER_ADMIN: "Супер-адміністратор",
    ROLE_CARRIER: "Перевізник",
    ROLE_MUNICIPALITY: "Муніціпалітет",
    ROLE_OPERATOR: "Оператор",
    ROLE_DISPATCHER: "Диспетчер",
    ROLE_MECHANIC: "Механік",
    ROLE_REPORTING_GROUP: "Звітна група",
    ROLE_DRIVER: "Водій",
    ROLE_USER: "Користувач",
  },
  resources: {
    carriers: {
      name: "Перевізник |||| Перевізники",
      fields: {
        index: "#",
        email: "Email адреса",
        role: "Участник системи",
        password: "Пароль",
        passwordRepeat: "Повторіть пароль",
        companyName: "Офіційна назва компанії",
        fullName: "ПІБ",
        phone: "Номер телефону",
        taxNumber: "Код ЄДРПОУ",
        otherDetail: "Інші реквізити",
        status: "Активний",
        regionName: "Область",
        districtName: "Населений пункт",
        cityName: "Місто",
        cityPostCode: "Почтовий індекс",
      },
    },
    "carrier-requests": {
      name: "Заявка |||| Заявки",
      watch: "Переглянути",
      fields: {
        index: "#",
        email: "Email адреса",
        role: "Участник системи",
        companyName: "Офіційна назва компанії",
        fullName: "ПІБ",
        phone: "Номер телефону",
        taxNumber: "Код ЄДРПОУ",
        otherDetail: "Інші реквізити",
      },
      empty: "Заявок немає",
      preAccept: "Попередньо схвалити",
      decline: "Відхилити",
      declinePrompt: "Вкажіть причину відхилення заявки. Цей текст буде надіслано заявнику..",
      declineMessageTitle: "Ваш коментар",
      messageError: "Введіть текст",
      activate: "Активувати",
    },
    "carrier-payments": {
      name: "Оплата |||| Оплати",
      fields: {
        id: "Номер підписки",
        paymentId: "Номер платежу",
        uid: "ID Beacon",
        companyName: "Перевізник",
        tailNumber: "Бортовий Номер",
        vehicleNumber: "Номер Автобуса",
        type: "Метод оплати",
      },
    },
    "subscription-requests": {
      name: "Заявки на підписки",
      watch: "Переглянути",
      fields: {
        index: "#",
        companyName: "Офіційна назва компанії",
        taxNumber: "Код ЄДРПОУ",
        regionName: "Область",
        districtName: "Населений пункт",
        cityName: "Місто",
        paymentId: "Номер рахунку на оплату",
        amount: "Сумма згідно рахунка"
      },
      empty: "Заявок немає",
      accept: "Схвалити",
      decline: "Відхилити",
      messageError: "Введіть текст",
    },
    subscriptions: {
      name: "Підписка |||| Підписки",
      watch: "Переглянути",
      fields: {
        idBeacon: "idBeacon",
        tailNumber: "Бортовий Номер",
        vehicleNumber: "Номер Автобуса",
        expired: "Активний",
        validFrom: "Дата початку підписки",
        validUntil: "Дата закінчення підписки",
        term: "Строк подовження"
      },
      empty: "Підписок немає",
      accept: "Схвалити",
      decline: "Відхилити",
      messageError: "Введіть текст",
    },
    cars: {
      name: "Машина |||| Автопарк",
      fields: {
        index: "#",
        beaconUid: "ID Beacon",
        tailNumber: "Бортовий Номер",
        vehicleNumber: "Номер Автобуса",
        model: "Модель Автобуса",
        capacity: "Пасажиромісткість",
      },
      empty: "Автопарк пустий",
      invite: 'Чтобы добавить машину нажмите кнопку "Створити"',
      create: "Додати автобус",
    },
    routes: {
      name: "Маршрут |||| Маршрути",
      fields: {
        index: "#",
        regionName: "Область",
        districtName: "Населений пункт",
        cityName: "Місто",
        cityPostCode: "Почтовий індекс",
        routeName: "Назва маршрута",
        routeNumber: "Номер маршрута",
        duration: "Тривалість маршрута (хв)",
        distance: "Довжина маршрута (км)",
        price: "Тариф Маршрута (грн)",
        startPoint: "Початкова зупинка",
        endPoint: "Кінцева зупинка",
        note: "Примітки до маршруту",
      },
      empty: "Покищо у вас немає жодного маршруту",
      invite: 'Чтобы добавить маршрут нажмите кнопку "Створити"',
    },
    cities: {
      name: "Місто |||| Міста",
      fieldName: "Місто",
      empty: "Немає Міст",
      fields: {
        name: "Назва",
        index: "#",
      },
      values: {
        kiev: "Київ",
        kharkov: "Харків",
        sumy: "Суми",
      },
    },
    drivers: {
      name: "Водій |||| Водії",
      fields: {
        index: "#",
        email: "Электронна адреса",
        fullName: "ПІБ",
        personalNumber: "Табельний номер",
        role: "Участник системи",
        password: "Пароль",
      },
      empty: "Поки що у вас немає водіїв",
      invite: 'Чтобы добавить водителя нажмите кнопку "Створити"',
      create: "Додати водія",
    },
    dispatchers: {
      name: "Водій |||| Водії",
      fields: {
        index: "#",
        uid: "ID Beacon",
        routeNumber: "Номер Маршруту",
        routeName: "Назва Маршруту",
        vehicleNumber: "Номер Автобуса",
        tailNumber: "Бортовий Номер",
        fullName: "Водій",
        personalNumber: "Табельний номер",
        dayRevenueAmount: "Виручка за зміну",
        revenuePerShift: "Здано в касу",
        closed: "Зміну закрито"
      },
      empty: "Поки що у вас немає водіїв",
      invite: 'Чтобы добавить водителя нажмите кнопку "Створити"',
      create: "Створити водія",
      close: "Закрити Зміну",
    },
    employees: {
      name: "Користувач |||| Користувачи",
      fields: {
        index: "#",
        email: "Электронна адреса",
        fullName: "ПІБ",
        role: "Роль",
        password: "Пароль",
      },
      empty: "Поки що у вас немає користувачів",
      invite: 'Чтобы добавить пользователя нажмите кнопку "Створити"',
      create: "Створити Користувача",
    },
    connections: {
      name: "Зв'язок' |||| Зв'язки",
      fields: {
        index: "#",
        carId: "Автобус",
        routeId: "Маршрут",
        beaconUid: "ID Beacon",
        routeName: "Назва Маршруту",
        routeNumber: "Номер Маршруту",
        tailNumber: "Бортовий Номер",
        vehicleNumber: "Номер Автобуса",
        driverId: "Водій",
      },
      empty: "Поки що у вас немає зв'язків",
      invite: 'Чтобы добавить связь нажмите кнопку "Створити"',
      create: "Створити зв'язок",
      delete: 'Удалить машину %{carNumber} из маршрута "%{routeName}" ?',
    },
    statistics: {
      name: "Статистика",
      fields: {
        index: "#",
        routeNumber: "Номер Маршруту",
        routeName: "Назва Маршруту",
        createdAt: "Дата",
        tailNumber: "Бортовий номер",
        driverFullName: "Водій",
        currentShift: "Перевезено пассажирів",
        dayRevenueAmount: "Денна выручка",
        dateStart: "Час з:",
        dateEnd: "Час до:",
      },
      summary: {
        totalPassengers: "Перевезено оплачених:",
        totalAmount: "Приход ДС:",
      },
      empty: "Немає статистики",
    },
    "driver-profile": {
      name: "Профіль",
      fields: {
        email: "Электронна адреса",
        fullName: "ПІБ",
        personalNumber: "Табельний номер",
        password: "Пароль",
      },
    },
    "carrier-beacons": {
      name: "Бікон  |||| Бікони",
      fields: {
        uid: "ID Beacon",
        major: "Major",
        minor: "Minor",
        macAddress: "Mac address",
        carrierCompanyName: "Перевізник",
        tailNumber: "Бортовий Номер",
        vehicleNumber: "Номер Автобуса",
      },
      create: "Додати Бікон",
    },
    "admin-beacons": {
      name: "Бікон  |||| Бікони",
      fields: {
        uid: "ID Beacon",
        major: "Major",
        minor: "Minor",
        macAddress: "Mac address",
        carrierCompanyName: "Перевізник",
      },
      create: "Додати Бікон",
    },
  },
};
