import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Field, useForm, useFormState } from "react-final-form";
import { makeValidate, Select, TextField } from "mui-rff";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import { useHistory } from "react-router-dom";
import * as PropTypes from "prop-types";
import clsx from "clsx";
import useTitle from "../../../hooks/useTitle.js";
import OrangeButton from "../../../SharedComponents/OrangeButton.jsx";
import icInfo from "../../../Assets/ic_info.svg";
import { ROLE_CARRIER } from "../../../Utils/Constants.js";
import ReactFinalForm from "../../../SharedComponents/ReactFinalForm.jsx";
import { useRegistrationContext } from "../../../Providers/Registration/registrationProvider.jsx";
import useRegions from "../../../hooks/useRegions.js";
import { get } from "../../../Utils/NetworkUtils.js";
import { API_URL } from "../../../Constants.js";
import useEffectAsync from "../../../hooks/useEffectAsync.js";
import mainStyles from "../../../Assets/js/mainStyles.js";

const schema = Yup.object().shape({
  phone: Yup.string().matches(/^\+380\d{9}$/, "Номер телефона должен быть в формате +380ХХХХХХХХХ"),
  email: Yup.string().email("Неверный Email"),
  taxNumber: Yup.string().matches(/^\d{8,}$/, "Неверный номер"),
});

const validate = makeValidate(schema);

const useStyles = makeStyles((theme) => ({
  carrierRoot: {
    backgroundColor: "#F5F5F5",
  },
  header: {
    fontWeight: 700,
    fontStyle: "italic",
    margin: "4vh 0 4vh",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  form: {},
  emailHint: {
    marginBottom: 20,
  },
  tooltip: {
    fontSize: 15,
  },
}));

const useStylesMain = makeStyles(mainStyles);

const SpecialSelect = ({ source, choices, label, setValue }) => {
  const formState = useFormState();
  const form = useForm();

  const selectHandler = ({ target: { value } }) => {
    setValue && setValue(value);
    form.change(source, value);
    if (source === "regionId") {
      form.change("districtId", "");
      form.change("cityId", "");
    }
    if (source === "districtId") {
      form.change("cityId", "");
    }
  };

  return (
    <Select
      name={source}
      value={formState.values[source] || ""}
      onChange={selectHandler}
      label={label}
      variant="outlined"
      required
    >
      <MenuItem value="">&nbsp;</MenuItem>
      {choices.map((choice) => (
        <MenuItem key={choice.id} value={choice.id}>
          {choice.name}
        </MenuItem>
      ))}
    </Select>
  );
};

SpecialSelect.propTypes = {
  choices: PropTypes.array,
  source: PropTypes.string,
  label: PropTypes.string,
  setValue: PropTypes.func,
};

SpecialSelect.defaultProps = {
  choices: [],
};

const CarrierRegistration = () => {
  const classes = useStyles();
  const classesMain = useStylesMain();
  const history = useHistory();
  const { setRegistrationData, registrationData } = useRegistrationContext();
  const regions = useRegions();
  const [regionId, setRegionId] = useState((registrationData && registrationData.regionId) || 0);
  const [districtId, setDistrictId] = useState((registrationData && registrationData.districtId) || 0);
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);

  useEffectAsync(async () => {
    /** @type {{data: {count: Number, items: Region[]}}} */
    const resp = regionId ? await get(`${API_URL}/districts/region/${regionId}`) : null;
    setDistricts(resp ? resp.data : []);
  }, [regionId]);

  useEffectAsync(async () => {
    /** @type {{data: {count: Number, items: Region[]}}} */
    const resp = regionId && districtId ? await get(`${API_URL}/cities/district/${districtId}`) : null;
    setCities(resp ? resp.data : []);
  }, [districtId, regionId]);

  useTitle("Квиток просто - Ввод регистрационных данных");

  const onDataSubmit = async (formValues) => {
    const formData = { ...formValues };
    if (formData.otherDetail === undefined) {
      formData.otherDetail = "";
    }
    setRegistrationData(formData);
    history.push("/registration/final");
  };

  return (
    <Box
      className={classes.carrierRoot}
      minHeight="calc(100vh - 48px)"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography className={classes.header} variant="h4">
        Данные для регистрации
      </Typography>
      <ReactFinalForm
        onSubmit={onDataSubmit}
        validate={validate}
        formClassName={classes.form}
        initialValues={registrationData}
      >
        <Field name="role" component="input" type="hidden" defaultValue={ROLE_CARRIER} />
        <Field name="status" component="input" type="hidden" defaultValue={false} />
        <Box className={classesMain.inputFields}>
          <SpecialSelect source="regionId" label="Область" choices={regions} setValue={(value) => setRegionId(value)} />
          <SpecialSelect
            source="districtId"
            label="Район"
            choices={districts}
            setValue={(value) => setDistrictId(value)}
          />
          <SpecialSelect source="cityId" label="Город" choices={cities} />
          <TextField name="fullName" label="ФИО" variant="outlined" required />
          <TextField name="phone" label="Номер телефона" variant="outlined" required />
          <TextField className="email-field" name="email" label="Email" variant="outlined" required />
          <Typography className={classes.emailHint} variant="body2">
            *на эту почту вам прийдёт письмо с подтверждением
          </Typography>
          <Box className={classesMain.iconBox}>
            <TextField name="companyName" label="Официальное название компании" variant="outlined" required />
            <Tooltip
              title="Официальное название компании"
              arrow
              placement="bottom-start"
              classes={{ tooltip: classes.tooltip }}
            >
              <img className={classesMain.infoIcon} src={icInfo} alt="" />
            </Tooltip>
          </Box>
          <Box className={classesMain.iconBox}>
            <TextField name="taxNumber" label="ЕГРПОУ" variant="outlined" required />
            <Tooltip title="ЕГРПОУ" arrow placement="bottom-start" classes={{ tooltip: classes.tooltip }}>
              <img className={classesMain.infoIcon} src={icInfo} alt="" />
            </Tooltip>
          </Box>
          <TextField name="otherDetail" label="Прочие реквизиты" variant="outlined" />
        </Box>
        <Box display="flex" justifyContent="space-between" margin="30px 0">
          <OrangeButton className={classesMain.button} onClick={() => history.goBack()}>
            Назад
          </OrangeButton>
          <Button className={clsx(classesMain.button, classesMain.primaryBtn)} type="submit">
            Далее
          </Button>
        </Box>
      </ReactFinalForm>
    </Box>
  );
};

export default CarrierRegistration;
