import React from "react";
import { Show, SimpleShowLayout, TextField } from "react-admin";
import MySimpleBreadcrumbs from "../../../../SharedComponents/MySimpleBreadcrumbs.jsx";

/*eslint-disable react/prop-types*/
const ShowRoute = (props) => (
  <Show {...props} title={<MySimpleBreadcrumbs resource={props.resource} />}>
    <SimpleShowLayout>
      <TextField source="regionName" />
      <TextField source="districtName" />
      <TextField source="cityName" />
      <TextField source="routeName" />
      <TextField source="routeNumber" />
      <TextField source="distance" />
      <TextField source="duration" />
      <TextField source="endPoint" />
      <TextField source="price" />
      <TextField source="startPoint" />
      <TextField source="note" />
    </SimpleShowLayout>
  </Show>
);

export default ShowRoute;
