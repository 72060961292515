import React from "react";
import { Datagrid, List, TextField, useTranslate, ReferenceField } from "react-admin";
import Typography from "@material-ui/core/Typography";
import EditButtons from "../../../../SharedComponents/EditButtons.jsx";
import MyPagination from "../../../../SharedComponents/MyPagination.jsx";
import useHasCreate from "../../../../hooks/useHasCreate.js";
import ListTextFilter from "../../../../SharedComponents/ListTextFilter.jsx";

const Session = (props) => {
  const { record } = props;
  return record.sessionActive ? (<Typography>Активная</Typography>) : (<Typography>Закрыта</Typography>);
}

export const ListConnections = (props) => {
  const t = useTranslate();
  const hasCreate = useHasCreate();

  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      perPage={25}
      pagination={<MyPagination />}
      filters={<ListTextFilter />}
    >
      <Datagrid>
        <TextField source="index" />
        <TextField source="routeName" />
        <TextField source="routeNumber" />
        <TextField source="vehicleNumber" />
        <TextField source="tailNumber" />
        <ReferenceField source="driverId" reference="drivers" link={false}>
          <TextField source="fullName" />
        </ReferenceField>
        <ReferenceField label={"Сессия"} source="driverId" reference="drivers" link={false}>
          <Session source="sessionActive"/>
        </ReferenceField>
        <EditButtons
          deleteConfirmTitle={
            /** @param {Connection} record */
            (record) =>
              `${t("resources.connections.delete", { carNumber: record.vehicleNumber, routeName: record.routeName })}`
          }
          hasView={!hasCreate}
          hasEdit={hasCreate}
          hasDelete={hasCreate}
        />
      </Datagrid>
    </List>
  );
};
