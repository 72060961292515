import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  useTranslate,
  useDataProvider,
  useRefresh,
  useRedirect,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import { useFormState } from "react-final-form";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "../../../../Providers/Common/snackbarProvider.jsx";
import MySimpleBreadcrumbs from "../../../../SharedComponents/MySimpleBreadcrumbs.jsx";
import RedToolbarButton from "../../../../SharedComponents/RedToolbarButton.jsx";
import ToolbarButton from "../../../../SharedComponents/ToolbarButton.jsx";
import RoleTextInputReadonly from "../../../../SharedComponents/RoleTextInputReadonly.jsx";

const useStyles = makeStyles((theme) => ({
  leftSpace: {
    margin: `0 0 0 ${theme.spacing(3)}px`,
  },
}));

const PostCommentDialog = ({ open, onResult }) => {
  const t = useTranslate();
  const [text, setText] = useState("");
  const [error, setError] = useState("");

  const defaultResultHandler = useCallback(() => {
    onResult();
  }, [onResult]);

  const confirmHandler = () => {
    if (text) {
      setError("");
      onResult(text);
    } else {
      setError(t("resources.carrier-requests.messageError"));
    }
  };

  const textInputHandler = useCallback((event) => {
    /** @type {String} */
    const value = event.target.value;
    if (value) {
      setError("");
    }
    setText(value);
  }, []);

  return (
    <Dialog open={open} onClose={defaultResultHandler}>
      <DialogContent>
        <DialogContentText>{t("resources.carrier-requests.declinePrompt")}</DialogContentText>
        <TextField
          autoFocus
          multiline
          rowsMax={4}
          error={!!error}
          margin="dense"
          label={t("resources.carrier-requests.declineMessageTitle")}
          fullWidth
          value={text}
          helperText={error}
          onChange={textInputHandler}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={defaultResultHandler} color="primary">
          {t("ra.action.cancel")}
        </Button>
        <Button onClick={confirmHandler} color="primary" variant="contained">
          {t("ra.action.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PostCommentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onResult: PropTypes.func.isRequired,
};

const AdminRequestToolbar = (props) => {
  const t = useTranslate();
  const classes = useStyles();
  const formState = useFormState();
  const { put, delete: del } = useDataProvider();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const snackbar = useSnackbar();
  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const acceptHandler = useCallback(async () => {
    /** @type Carrier */
    const data = { ...formState.values };
    delete data.id;
    data.status = true;
    try {
      await put(`carrier-requests/pre-approval/${formState.values.id}`, data);
      redirect("/carrier-requests");
      refresh();
    } catch (e) {
      snackbar.error(e.message);
    }
  }, [formState.values]);

  const activeHandler = useCallback(async () => {
    /** @type Carrier */
    const data = { ...formState.values };
    delete data.id;
    data.status = true;
    try {
      await put(`carrier-requests/activate/${formState.values.id}`, data);
      redirect("/carrier-requests");
      refresh();
    } catch (e) {
      snackbar.error(e.message);
    }
  }, [formState.values]);


  const RenderButton = (props) => {
    const {record} = props;
    const t = useTranslate();

    return (
      <>
        { record.status === 'new' ?
          <ToolbarButton variant="contained" color="primary" onClick={acceptHandler}>
            {t("resources.carrier-requests.preAccept")}
          </ToolbarButton>
          :
          <ToolbarButton variant="contained" color="primary" onClick={activeHandler}>
            {t("resources.carrier-requests.activate")}
          </ToolbarButton>
        }
      </>
    )
  }

  const declineHandler = useCallback(
    async (message) => {
      setOpen(false);
      if (!message) {
        return;
      }
      try {
        await del("carrier-requests", {
          id: formState.values.id,
          query: {
            message,
          },
        });
        redirect("/carrier-requests");
        refresh();
      } catch (e) {
        snackbar.error(e.message);
      }
    },
    [formState.values],
  );

  return (
    <>
      <Toolbar {...props}>
        <RenderButton/>
        <RedToolbarButton className={classes.leftSpace} variant="contained" onClick={handleClickOpen}>
          {t("resources.carrier-requests.decline")}
        </RedToolbarButton>
      </Toolbar>
      <PostCommentDialog open={open} onResult={declineHandler} />
    </>
  );
};

const OtherDetailsTextInputReadonly = (props) => {
  return props.record && props.record.otherDetail !== "" ? (
    <TextInput {...props} source="otherDetail" disabled variant="outlined" />
  ) : null;
};

const ProtectedForm = (props) => {
  const { record } = props;
  const redirect = useRedirect();

  useEffect(() => {
    if (record && record.status === true) {
      redirect("/carrier-requests");
    }
  }, [record]);

  if (!record) {
    return null;
  }

  return <SimpleForm {...props} />;
};

/*eslint-disable react/prop-types*/
const EditCarrierRequest = (props) => {
  return (
    <Edit {...props} undoable={false} title={<MySimpleBreadcrumbs resource={props.resource} />}>
      <ProtectedForm toolbar={<AdminRequestToolbar />}>
        <RoleTextInputReadonly source="role" variant="outlined" />
        <TextInput source="fullName" disabled variant="outlined" />
        <TextInput source="phone" disabled variant="outlined" />
        <TextInput source="email" disabled variant="outlined" />
        <TextInput source="companyName" disabled variant="outlined" />
        <TextInput source="taxNumber" disabled variant="outlined" />
        <OtherDetailsTextInputReadonly />
      </ProtectedForm>
    </Edit>
  );
};

export default EditCarrierRequest;
