import React, {useCallback, useEffect, useState} from "react";
import {Datagrid, List, TextField} from "react-admin";
import CheckBox from "@material-ui/core/Checkbox";
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import {Button, Typography, Box, makeStyles, Select, MenuItem } from "@material-ui/core";
import MyPagination from "../../../../SharedComponents/MyPagination.jsx";
import ListTextFilter from "../../../../SharedComponents/ListTextFilter.jsx";
import {useSnackbar} from "../../../../Providers/Common/snackbarProvider.jsx";
import dataProvider from "../../providers/dataProvider.js";
import {addWorkDays, dateToString} from "../../../../Utils/DateUtils.js";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    overflowX: "auto",
    marginBottom: 25,
  },
  table: {
    "& th": {
      textAlign: "center"
    },
    "& td": {
      textAlign: "center",
    },
    "& tr": {
      "&:nth-child(2n+1)": {
        backgroundColor: "#F9F9F9",
      }
    },
  },
  tableSelect: {
    minWidth: 50,
  },
  tableInput: {
    maxWidth: 200,
    overflow: "hidden",
  },
  payWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  paySelect: {
    minWidth: 250,
    marginRight: 15,
  },
  inProgress: {
    position: "relative",
    left: -9,
    "&:before": {
      content: '""',
      width: 10,
      height: 10,
      borderRadius: "50%",
      backgroundColor: "#e7a808",
      position: "absolute",
      top: "50%",
      left: -15,
      transform: "translateY(-50%)",
    },
  },
  disActive: {
    position: "relative",
    "&:before": {
      content: '""',
      width: 10,
      height: 10,
      borderRadius: "50%",
      backgroundColor: "#D80027",
      position: "absolute",
      top: "50%",
      left: -15,
      transform: "translateY(-50%)",
    },
  },
  active: {
    position: "relative",
    left: -9,
    "&:before": {
      content: '""',
      width: 10,
      height: 10,
      borderRadius: "50%",
      backgroundColor: "#2C8B00",
      position: "absolute",
      top: "50%",
      left: -15,
      transform: "translateY(-50%)",
    },
  },
}));

/*eslint-disable react/prop-types*/
export const MyCheckBox = (props) => {
  const {record, handleChangeCheckBox} = props;

  return (
    <CheckBox onClick={e => handleChangeCheckBox(e, record.id)}/>
  );
}

/*eslint-disable react/prop-types*/
export const DateMonthTextField = (props) => {
  const {record, dateType} = props;
  return (<Typography>{dateToString(new Date(record[dateType]), "dd.MM.yyyy")}</Typography>);
}

/*eslint-disable react/prop-types*/
export const MyDatePicker = (props) => {
  const { record, paymentType, clearRowId, handleChangeDate, classes } = props;
  const [selectedDate, setSelectedDate] = useState(null);
  const [minDate, setMinDate] = useState(null);

  const handleChange = (date, id) => {
    setSelectedDate(date);
    handleChangeDate(date, id);
  };

  useEffect(() => {
    if (clearRowId === record.id) {
      setSelectedDate(null);
    }

    const date = record.minDate ? new Date(record.minDate) : new Date();
    setMinDate(addWorkDays(date, paymentType === 1 ? 4 : 6))

  }, [clearRowId, paymentType])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        disableToolbar
        format="dd.MM.yyyy"
        onChange={e => handleChange(e, record.id)}
        value={selectedDate}
        minDate={minDate}
        className={classes.tableInput}
      />
    </MuiPickersUtilsProvider>
  );
}

function MySelect(props) {
  const { record, clearRowId, handleChangeSelect, classes } = props;

  const terms = Array.from({length: 12}, (_, i) => i + 1);
  const [selected, setTerm] = useState(1)

  const handleChange = ({target}) => {
    setTerm(target.value);
    handleChangeSelect(target.value, record.id)
  }

  useEffect(() => {
    if (clearRowId === record.id) {
      setTerm(1);
    }
  }, [clearRowId])

  return (
    <Select source="term" value={selected} onChange={handleChange} className={classes.tableSelect}>
      {terms.map(item =>
        <MenuItem key={item} value={item}>{item}</MenuItem>
      )}
    </Select>);
}

export const ListSubscriptions = (props) => {
  const classes = useStyles();
  const { create } = dataProvider;
  const snackbar = useSnackbar();
  const [data, setData] = useState({
      typeId: 1, // default payment type by card
      subscriptions: []
    });
  const [clearRowId, setRowId] = useState(null);
  const [paymentType, setPaymentType] = useState(1);

  const handleChangeCheckBox = (event, id) => {
    const { target: { checked } } = event;
    const { subscriptions } = data;

    if (checked) {
      setRowId(null);
      setData(state =>({
        ...state, subscriptions:[...state.subscriptions, {subscriptionId:id, term: 1}]
      }));
    } else {
      let newData = subscriptions.filter(subscription => {
        if (subscription.subscriptionId !== id) {
          return subscription;
        }
      })

      setData(state =>({
        ...state, subscriptions: newData
      }));
      setRowId(id);
    }
  }

  const handleChangeDate = (date, id) => {
    const { subscriptions } = data;

    let newData = subscriptions.map(subscription => {
      if (subscription.subscriptionId === id) {
        subscription.dateStart = date;
        return subscription;
      }else{
        return subscription;
      }
    })

    setData(state =>({
      ...state, subscriptions: newData
    }));
  }

  const handleChangeSelect = (term, id) => {
    const { subscriptions } = data;

    let newData = subscriptions.map(subscription => {
      if (subscription.subscriptionId === id) {
        subscription.term = term;
        return subscription;
      }else{
        return subscription;
      }
    })

    setData(state =>({
      ...state, subscriptions: newData
    }));
  }

  const Expired = ({record}) => {
    const { expired, subscriptionRequest } = record;

     if (!expired) {
      return (
        <Typography className={classes.active}
        >Активен</Typography>
      )
    } else if (
       (subscriptionRequest && !subscriptionRequest.status) ||
       (subscriptionRequest && subscriptionRequest.status && expired)
     ) {
       return (
         <Typography className={classes.inProgress}
         >Ожидание</Typography>
       )
     }

    return (
      <Typography className={classes.disActive}
      >Неактивен</Typography>
    )
  }

  const selectType = ({ target }) => {
    const { value } = target;
    setPaymentType(value);
    setData(state =>({
      ...state, typeId: value
    }));
  }

  const acceptHandler = useCallback(async () => {
    try {
      const response = await create("subscriptions/buy", {data});
      if (response.data) {
        window.open(response.data.url, '_blank')
      } else {
        snackbar.error(`${response.error.code}: ${response.error.text}`);
      }
    } catch (e) {
      snackbar.error(e.toString());
    }
  }, [data]);

  return (
    <>
      <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        hasCreate={false}
        perPage={10}
        pagination={<MyPagination />}
        filters={<ListTextFilter />}
        className={classes.tableWrapper}
      >
        <Datagrid className={classes.table}>
          <TextField source="vehicleNumber" />
          <TextField source="tailNumber" />
          <TextField source="beaconUid" />
          <Expired label="Статус"/>
          <DateMonthTextField dateType="validFrom" source="validFrom"/>
          <DateMonthTextField dateType="validUntil" source="validUntil"/>
          <MyCheckBox label="Выбрать" handleChangeCheckBox={handleChangeCheckBox}/>
          <MyDatePicker label="Дата старта" paymentType={paymentType} clearRowId={clearRowId} handleChangeDate={handleChangeDate} classes={classes} />
          <MySelect label="Период (месяц)" clearRowId={clearRowId} handleChangeSelect={handleChangeSelect} classes={classes} />
        </Datagrid>
      </List>
      <Box className={classes.payWrapper}>
        <Select className={classes.paySelect} variant="outlined" value={paymentType} onChange={selectType}>
          <MenuItem value={1}>Оплата картой</MenuItem>
          <MenuItem value={2}>Банковский перевод</MenuItem>
        </Select>
        <Button variant="contained" color="primary" onClick={acceptHandler}>Оплатить</Button>
      </Box>
    </>
  );
};
