import React from "react";
import {Admin, Resource} from "react-admin";
import {createBrowserHistory} from "history";
// icons
import AirlineSeatReclineNormalRoundedIcon from "@material-ui/icons/AirlineSeatReclineNormalRounded";
import PeopleRoundedIcon from "@material-ui/icons/PeopleRounded";
import SettingsInputComponentRoundedIcon from "@material-ui/icons/SettingsInputComponentRounded";
import EqualizerRoundedIcon from "@material-ui/icons/EqualizerRounded";
import LocalAtmRoundedIcon from "@material-ui/icons/LocalAtmRounded";
import ListAltRoundedIcon from "@material-ui/icons/ListAltRounded";
import PaymentRoundedIcon from "@material-ui/icons/PaymentRounded";
import BusinessCenterRoundedIcon from "@material-ui/icons/BusinessCenterRounded";
import NoteAddRoundedIcon from "@material-ui/icons/NoteAddRounded";
import AirportShuttleRoundedIcon from "@material-ui/icons/AirportShuttleRounded";
import SwapCallsRoundedIcon from "@material-ui/icons/SwapCallsRounded";
import BluetoothRoundedIcon from '@material-ui/icons/BluetoothRounded';

import {Route} from "react-router-dom";
import dataProvider from "./providers/dataProvider.js";
import MyLayout from "./layout/MyLayout.jsx";
import i18nProvider from "../../Providers/i18n/i18nProvider.js";
import {ListCarriers} from "./resources/carriers/ListCarriers.jsx";
import AdminLogin from "./AdminLogin.jsx";
import EditCarrier from "./resources/carriers/EditCarrier.jsx";
import {ListCarrierRequests} from "./resources/carrier-requests/ListCarrierRequests.jsx";
import EditCarrierRequest from "./resources/carrier-requests/EditCarrierRequest.jsx";
import {ListCars} from "./resources/cars/ListCars.jsx";
import {
  ROLE_CARRIER, ROLE_DISPATCHER,
  ROLE_DRIVER,
  ROLE_MECHANIC,
  ROLE_OPERATOR,
  ROLE_REPORTING_GROUP,
  ROLE_SUPER_ADMIN,
} from "../../Utils/Constants.js";
import CreateCar from "./resources/cars/CreateCar.jsx";
import EditCar from "./resources/cars/EditCar.jsx";
import useAuthProvider from "./providers/authProvider.js";
import adminTheme from "./adminTheme.js";
import {ListRoutes} from "./resources/routes/ListRoutes.jsx";
import CreateRoute from "./resources/routes/CreateRoute.jsx";
import EditRoute from "./resources/routes/EditRoute.jsx";
// import { ListCities } from "./resources/cities/ListCities.jsx";
// import CreateCity from "./resources/cities/CreateCity.jsx";
import CreateDriver from "./resources/drivers/CreateDriver.jsx";
import {ListDrivers} from "./resources/drivers/ListDrivers.jsx";
import {ListDrivers as ListDispatcherDrivers} from "./resources/dispatchers/ListDrivers.jsx";
import EditDriver from "./resources/drivers/EditDriver.jsx";
import {ListEmployees} from "./resources/employees/ListEmployees.jsx";
import CreateEmployee from "./resources/employees/CreateEmployee.jsx";
import EditEmployee from "./resources/employees/EditEmployee.jsx";
import CreateConnection from "./resources/connections/CreateConnection.jsx";
import {ListConnections} from "./resources/connections/ListConnections.jsx";
import EditConnection from "./resources/connections/EditConnection.jsx";
import {ListStatistics} from "./resources/statistics/ListStatistics.jsx";
import MyDriverProfile from "./resources/drivers/MyDriverProfile.jsx";
import NotFound from "../../SharedComponents/NotFound.jsx";
import ShowCar from "./resources/cars/ShowCar.jsx";
import ShowRoute from "./resources/routes/ShowRoute.jsx";
import ShowDriver from "./resources/drivers/ShowDriver.jsx";
import ShowConnection from "./resources/connections/ShowConnection.jsx";
import {ListSubscriptionRequests} from "./resources/subscription-requests/ListSubscriptionRequests.jsx";
import EditSubscriptionRequest from "./resources/subscription-requests/EditSubscriptionRequest.jsx";
import {ListSubscriptions} from "./resources/subscriptions/ListSubscriptions.jsx";
import {ListCarrierPayments} from "./resources/carrier-payments/ListCarrierPayments.jsx";
import {ListBeacon as AdminListBeacon} from "./resources/admin-beacons/ListBeacon.jsx";
import {ListBeacon as CarrierListBeacon} from "./resources/carrier-beacons/ListBeacon.jsx";
import EditBeacon from "./resources/admin-beacons/EditBeacon.jsx";
import CreateBeacon from "./resources/admin-beacons/CreateBeacon.jsx";
import ShowBeacon from "./resources/carrier-beacons/ShowBeacon.jsx";

const reactAdminHistory = createBrowserHistory({basename: "admin"});

const sharedResources = {
  statistics: {
    name: "statistics",
    list: ListStatistics,
    icon: EqualizerRoundedIcon,
  },
  cars: {
    name: "cars",
    list: ListCars,
    create: CreateCar,
    edit: EditCar,
    icon: AirportShuttleRoundedIcon,
  },
  routes: {
    name: "routes",
    list: ListRoutes,
    create: CreateRoute,
    edit: EditRoute,
    icon: SwapCallsRoundedIcon,
  },
  drivers: {
    name: "drivers",
    list: ListDrivers,
    create: CreateDriver,
    edit: EditDriver,
    icon: AirlineSeatReclineNormalRoundedIcon,
  },
  connections: {
    name: "connections",
    list: ListConnections,
    create: CreateConnection,
    edit: EditConnection,
    icon: SettingsInputComponentRoundedIcon,
  },
  dispatchers: {
    name: "dispatchers",
    list: ListDispatcherDrivers,
    icon: AirlineSeatReclineNormalRoundedIcon,
  },
  carrierBeacons: {
    name: "carrier-beacons",
    list: CarrierListBeacon,
    show: ShowBeacon,
    icon: BluetoothRoundedIcon,
  },

};

const adminResources = [
  {
    name: "carriers",
    list: ListCarriers,
    edit: EditCarrier,
    icon: BusinessCenterRoundedIcon,
  },
  {
    name: "carrier-requests",
    list: ListCarrierRequests,
    edit: EditCarrierRequest,
    icon: NoteAddRoundedIcon,
  },
  {
    name: "carrier-payments",
    list: ListCarrierPayments,
    icon: PaymentRoundedIcon,
  },
  {
    name: "subscription-requests",
    list: ListSubscriptionRequests,
    edit: EditSubscriptionRequest,
    icon: LocalAtmRoundedIcon,
  },
  {
    name: "admin-beacons",
    list: AdminListBeacon,
    create: CreateBeacon,
    edit: EditBeacon,
    icon: BluetoothRoundedIcon,
  },
  // {
  //   name: "cities",
  //   list: ListCities,
  //   create: CreateCity,
  //   icon: ApartmentRoundedIcon,
  // },
];

const carrierResources = [
  {
    name: "employees",
    list: ListEmployees,
    create: CreateEmployee,
    edit: EditEmployee,
    icon: PeopleRoundedIcon,
  },
  {
    name: "cars",
    list: ListCars,
    show: ShowCar,
    icon: AirportShuttleRoundedIcon,
  },
  {
    name: "routes",
    list: ListRoutes,
    show: ShowRoute,
    icon: SwapCallsRoundedIcon,
  },
  {
    name: "drivers",
    list: ListDrivers,
    show: ShowDriver,
    icon: AirlineSeatReclineNormalRoundedIcon,
  },
  {
    name: "connections",
    list: ListConnections,
    show: ShowConnection,
    icon: SettingsInputComponentRoundedIcon,
  },
  {
    name: "subscriptions",
    list: ListSubscriptions,
    icon: ListAltRoundedIcon,
  },
  sharedResources.statistics,
  sharedResources.carrierBeacons,
  // next resource is needed for ReferenceInput and ReferenceField
  {name: "cities"},
];

const operatorResources = [
  sharedResources.cars,
  sharedResources.routes,
  sharedResources.drivers,
  sharedResources.connections,
  sharedResources.carrierBeacons,
  {name: "carrier-car-beacons"},
  // next resources is needed for ReferenceInput and ReferenceField
  {name: "cities"},
];

const dispatcherResources = [
  sharedResources.dispatchers,
];

const mechanicResources = [
  sharedResources.connections,
  // next resources is needed for ReferenceInput and ReferenceField
  {name: "cars"},
  {name: "routes"},
  {name: "drivers"},
];

const reportingGroupResources = [
  sharedResources.statistics,
  // next resources is needed for ReferenceInput
  {name: "cars"},
  {name: "routes"},
];

const driverResources = [{name: "driver-profile"}];

function getResources() {
  /** @type Permissions */
  const permissions = JSON.parse(localStorage.getItem("user"));
  if (permissions) {
    let resources = [<Resource key="unknown" name="unknown"/>];
    switch (permissions.user.roles[0]) {
      case ROLE_SUPER_ADMIN:
        resources = adminResources;
        break;
      case ROLE_CARRIER:
        resources = carrierResources;
        break;
      case ROLE_OPERATOR:
        resources = operatorResources;
        break;
      case ROLE_MECHANIC:
        resources = mechanicResources;
        break;
      case ROLE_REPORTING_GROUP:
        resources = reportingGroupResources;
        break;
      case ROLE_DRIVER:
        resources = driverResources;
        break;
      case ROLE_DISPATCHER:
        resources = dispatcherResources;
        break;
    }
    return resources.map((resource) => <Resource key={resource.name} {...resource} />);
  }
}

const AdminRoot = () => {
  const authProvider = useAuthProvider();

  return (
    <Admin
      catchAll={NotFound}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      authProvider={authProvider}
      history={reactAdminHistory}
      loginPage={AdminLogin}
      theme={adminTheme}
      layout={MyLayout}
      customRoutes={[<Route key="driver-profile" path="/driver-profile" component={MyDriverProfile}/>]}
    >
      {getResources}
    </Admin>
  );
};

export default AdminRoot;
