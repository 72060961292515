import React from "react";
import {Edit, SimpleForm, TextInput, required, NumberInput, ReferenceInput, SelectInput} from "react-admin";
import MyStandardEditToolbar from "../../../../SharedComponents/MyStandardEditToolbar.jsx";
import MySimpleBreadcrumbs from "../../../../SharedComponents/MySimpleBreadcrumbs.jsx";

const validateRequired = required();

/*eslint-disable react/prop-types*/
const SpecialSelect = (props) => {
  return (
    <SelectInput
      {...props}
      optionText="companyName"
    />
  );
};

/*eslint-disable react/prop-types*/
const EditBeacon = (props) => {
  return (
    <Edit {...props} undoable={false} title={<MySimpleBreadcrumbs resource={props.resource} />}>
      <SimpleForm redirect="list" toolbar={<MyStandardEditToolbar nameField="uid" />}>
        <TextInput source="uid" validate={validateRequired} />
        <NumberInput source="major" step={1} min={0} validate={validateRequired} />
        <NumberInput source="minor" step={1} min={0} validate={validateRequired} />
        <TextInput source="macAddress" validate={validateRequired} />
        <ReferenceInput source="carrierId" reference="carriers">
          <SpecialSelect />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default EditBeacon;
