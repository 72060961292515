export default {
  ra: {
    action: {
      add_filter: "Добавить фильтр",
      add: "Добавить",
      back: "Назад",
      bulk_actions: "1 выбран |||| %{smart_count} выбрано |||| %{smart_count} выбрано",
      cancel: "Отмена",
      clear_input_value: "Очистить",
      clone: "Дублировать",
      confirm: "Подтвердить",
      create: "Создать",
      delete: "Удалить",
      edit: "Изменить",
      export: "Экспорт в Excel",
      list: "Список",
      refresh: "Обновить",
      remove_filter: "Убрать фильтр",
      remove: "Удалить",
      save: "Сохранить",
      search: "Поиск",
      show: "Просмотр",
      sort: "Сортировка",
      undo: "Отменить",
      unselect: "Отменить выбор",
      expand: "Раскрыть",
      close: "Закрыть",
      open_menu: "Открыть меню",
      close_menu: "Закрыть меню",
    },
    boolean: {
      true: "Да",
      false: "Нет",
      null: " ",
    },
    page: {
      create: "Создать %{name}",
      dashboard: "Главная",
      edit: "%{name} #%{id}",
      error: "Что-то пошло не так",
      list: "%{name}",
      loading: "Загрузка",
      not_found: "Не найдено",
      show: "%{name} #%{id}",
      empty: "Нет %{name}.", // not used
      invite: "Вы хотите добавить еще одну?", // not used
    },
    input: {
      file: {
        upload_several: "Перетащите файлы сюда или нажмите для выбора.",
        upload_single: "Перетащите файл сюда или нажмите для выбора.",
      },
      image: {
        upload_several: "Перетащите изображения сюда или нажмите для выбора.",
        upload_single: "Перетащите изображение сюда или нажмите для выбора.",
      },
      references: {
        all_missing: "Связанных данных не найдено",
        many_missing: "Некоторые из связанных данных не доступны",
        single_missing: "Связанный объект не доступен",
      },
      password: {
        toggle_visible: "Скрыть пароль",
        toggle_hidden: "Показать пароль",
      },
    },
    message: {
      about: "Справка",
      are_you_sure: "Вы уверены?",
      bulk_delete_content: "Вы уверены что хотите удалить этот объект |||| Вы уверены, что хотите удалить объекты, кол-вом %{smart_count} ? |||| Вы уверены, что хотите удалить объекты, кол-вом %{smart_count}?",
      bulk_delete_title: "Удалить объект |||| Удалить %{smart_count} объекта |||| Удалить %{smart_count} объектов",
      delete_content: "Вы уверены что хотите удалить этот объект",
      delete_title: 'Удалить %{name} "%{id}"',
      details: "Описание",
      error: "В процессе запроса возникла ошибка, и он не может быть завершен",
      invalid_form: "Форма заполнена неверно, проверьте, пожалуйста, ошибки",
      loading: "Идет загрузка, пожалуйста, подождите...",
      no: "Нет",
      not_found: "Ошибка URL или вы следуете по неверной ссылке",
      yes: "Да",
      unsaved_changes: "Некоторые из ваших изменений не были сохранены. Вы уверены, что хотите их игнорировать?",
    },
    navigation: {
      no_results: "Результатов не найдено",
      no_more_results: "Страница %{page} выходит за пределы нумерации, попробуйте предыдущую",
      page_out_of_boundaries: "Страница %{page} вне границ",
      page_out_from_end: "Невозможно переместиться дальше последней страницы",
      page_out_from_begin: "Номер страницы не может быть меньше 1",
      page_range_info: "%{offsetBegin}-%{offsetEnd} из %{total}",
      page_rows_per_page: "Строк на странице:",
      next: "Следующая",
      prev: "Предыдущая",
      skip_nav: "?",
    },
    sort: {
      sort_by: "Сортировать по %{field} %{order}",
      ASC: "возрастанию",
      DESC: "убыванию",
    },
    auth: {
      auth_check_error: "Пожалуйста, авторизуйтесь для продолжения работы",
      user_menu: "Профиль",
      username: "Имя пользователя", // not used
      password: "Пароль", // not used
      sign_in: "Войти", // not used
      sign_in_error: "Ошибка аутентификации, попробуйте снова", // not used
      logout: "Выйти",
    },
    notification: {
      updated: "Элемент обновлен |||| %{smart_count} обновлено |||| %{smart_count} обновлено",
      created: "Элемент создан",
      deleted: "Элемент удален |||| %{smart_count} удалено |||| %{smart_count} удалено",
      bad_item: "Элемент не валиден",
      item_doesnt_exist: "Элемент не существует",
      http_error: "Ошибка сервера",
      data_provider_error: "Ошибка dataProvider, проверьте консоль",
      i18n_error: "Не удалось загрузить перевод для указанного языка", // not used
      canceled: "Операция отменена",
      logged_out: "Ваша сессия завершена, попробуйте переподключиться/войти снова",
    },
    validation: {
      required: "Обязательно для заполнения",
      minLength: "Минимальное кол-во символов %{min}",
      maxLength: "Максимальное кол-во символов %{max}",
      minValue: "Минимальное значение %{min}",
      maxValue: "Значение может быть %{max} или меньше",
      number: "Должно быть цифрой",
      email: "Некорректный email",
      oneOf: "Должно быть одним из: %{options}",
      regex: "Должно быть в формате (regexp): %{pattern}",
    },
  },
  errors: {
    loadData: "Ошибка загрузки данных",
    notFound: "Не найдено",
    downloadFailed: "Ошибка загрузки",
    uploadError: "Ошибка загрузки: ",
    parsingError: "Parsing error: ",
    clickToRefresh: "Нажмите, чтобы обновить",
    routeNotFound: "Выберите интересующий вас раздел в меню слева",
  },
  validate: {
    startWhitespace: "Не должно начинаться с пробела",
    endWhitespace: "Не должно заканчиваться пробелом",
    url: "Этот url-адрес недоступен",
    html: "Текст не должен содержать код html",
    checked: "Необходимо поставить галочку",
    passwordsEquals: "Пароли должны совпадать",
  },
  common: {
    created: "Создан",
    updated: "Обновлен",
    autoSaving: "Сохранение...",
    autoSaved: "Сохранено",
    superAdmin: "Super Admin",
    appName: 'Портал "Квиток Просто"',
    currency: "грн",
  },
  roles: {
    ROLE_SUPER_ADMIN: "Супер-администратор",
    ROLE_CARRIER: "Перевозчик",
    ROLE_MUNICIPALITY: "Муниципалитет",
    ROLE_OPERATOR: "Оператор",
    ROLE_DISPATCHER: "Диспетчер",
    ROLE_MECHANIC: "Механик",
    ROLE_REPORTING_GROUP: "Отчётная группа",
    ROLE_DRIVER: "Водитель",
    ROLE_USER: "Пользователь",
  },
  resources: {
    carriers: {
      name: "Перевозчик |||| Перевозчики",
      fields: {
        index: "#",
        email: "Email адрес",
        role: "Участник системы",
        password: "Пароль",
        passwordRepeat: "Повторите пароль",
        companyName: "Официальное название компании",
        fullName: "ФИО",
        phone: "Номер телефона",
        taxNumber: "ЕГРПОУ",
        otherDetail: "Прочие реквизиты",
        status: "Активен",
        regionName: "Область",
        districtName: "Населённый пункт",
        cityName: "Город",
        cityPostCode: "Почтовый индекс",
      },
    },
    "carrier-requests": {
      name: "Заявка |||| Заявки",
      watch: "Просмотреть",
      fields: {
        index: "#",
        email: "Email адрес",
        role: "Участник системы",
        companyName: "Официальное название компании",
        fullName: "ФИО",
        phone: "Номер телефона",
        taxNumber: "ЕГРПОУ",
        otherDetail: "Прочие реквизиты",
      },
      empty: "Заявок нет",
      preAccept: "Предварительно одобрить",
      decline: "Отклонить",
      declinePrompt: "Напишите по какой причине вы отклонили заявку. Этот текст будет отправлен на почту указанной в заявке.",
      declineMessageTitle: "Ваш комментарий",
      messageError: "Введите текст",
      activate: "Активировать",
    },
    "carrier-payments": {
      name: "Оплата |||| Оплаты",
      fields: {
        id: "Номер подписки",
        paymentId: "Номер платежа",
        uid: "ID Beacon",
        companyName: "Перевозчик",
        tailNumber: "Бортовой Номер",
        vehicleNumber: "Номер Транспортного Средства",
        type: "Тип оплаты",
      },
    },
    "subscription-requests": {
      name: "Заявки на подписки",
      watch: "Просмотреть",
      fields: {
        index: "#",
        companyName: "Официальное название компании",
        taxNumber: "ЕГРПОУ",
        regionName: "Область",
        districtName: "Населённый пункт",
        cityName: "Город",
        paymentId: "Номер счета на оплату",
        amount: "Сумма по счету"
      },
      empty: "Заявок нет",
      accept: "Одобрить",
      decline: "Отклонить",
      messageError: "Введите текст",
    },
    subscriptions: {
      name: "Подписка |||| Подписки",
      watch: "Просмотреть",
      fields: {
        idBeacon: "idBeacon",
        tailNumber: "Бортовой Номер",
        vehicleNumber: "Номер Транспортного Средства",
        expired: "Активен",
        validFrom: "Дата начала подписки",
        validUntil: "Дата окончания подписки",
        term: "Срок продления"
      },
      empty: "Подписок нет",
      accept: "Одобрить",
      decline: "Отклонить",
      messageError: "Введите текст",
    },
    cars: {
      name: "Машина |||| Автопарк",
      fields: {
        index: "#",
        beaconUid: "ID Beacon",
        tailNumber: "Бортовой Номер",
        vehicleNumber: "Номер Транспортного Средства",
        model: "Тип/Модель Автомобиля",
        capacity: "Вместимость",
      },
      empty: "Автопарк пуст",
      invite: 'Чтобы добавить машину нажмите кнопку "создать"',
      create: "Добавить машину",
    },
    routes: {
      name: "Маршрут |||| Маршруты",
      fields: {
        index: "#",
        regionName: "Область",
        districtName: "Населённый пункт",
        cityName: "Город",
        cityPostCode: "Почтовый индекс",
        routeName: "Название маршрута",
        routeNumber: "Номер маршрута",
        duration: "Продолжительность маршрута (мин)",
        distance: "Длина маршрута (км)",
        price: "Стоимость Маршрута",
        startPoint: "Точка Отправления",
        endPoint: "Точка Прибытия",
        note: "Примечание к маршруту",
      },
      empty: "Пока у вас нет ни одного маршрута",
      invite: 'Чтобы добавить маршрут нажмите кнопку "создать"',
    },
    cities: {
      name: "Город |||| Города",
      fieldName: "Город",
      empty: "Нет городов",
      fields: {
        name: "Название",
        index: "#",
      },
      values: {
        kiev: "Киев",
        kharkov: "Харьков",
        sumy: "Сумы",
      },
    },
    drivers: {
      name: "Водитель |||| Водители",
      fields: {
        index: "#",
        email: "Электронный адрес",
        fullName: "ФИО",
        personalNumber: "Табельный номер",
        role: "Участник системы",
        password: "Пароль",
      },
      empty: "Пока у вас нет водителей",
      invite: 'Чтобы добавить водителя нажмите кнопку "создать"',
      create: "Создать водителя",
    },
    dispatchers: {
      name: "Выручка",
      fields: {
        index: "#",
        routeNumber: "Номер маршрутки",
        routeName: "Название Маршрута",
        vehicleNumber: "Гос номер ТС",
        tailNumber: "Бортовой Номер",
        driverFullName: "Водитель",
        driverPersonalNumber: "Табельный номер",
        createdAt: "Дата",
        dayRevenueAmount: "Выручка за смену",
        revenuePerShift: "Сдано в кассу",
        closed: "Смена закрыта"
      },
      empty: "Пока у вас нет водителей",
      invite: 'Чтобы добавить водителя нажмите кнопку "создать"',
      create: "Создать водителя",
      close: "Закрыть смену",
    },
    employees: {
      name: "Пользователь |||| Пользователи",
      fields: {
        index: "#",
        email: "Электронный адрес",
        fullName: "ФИО",
        role: "Роль",
        password: "Пароль",
      },
      empty: "Пока у вас нет пользователей",
      invite: 'Чтобы добавить пользователя нажмите кнопку "создать"',
      create: "Создать пользователя",
    },
    connections: {
      name: "Связь |||| Связи",
      fields: {
        index: "#",
        carId: "Машина",
        routeId: "Маршрут",
        beaconUid: "ID Beacon",
        routeName: "Название Маршрута",
        routeNumber: "Номер Маршрута",
        tailNumber: "Бортовой Номер",
        vehicleNumber: "Номер Транспортного Средства",
        driverId: "Водитель",
      },
      empty: "Пока у вас нет связей",
      invite: 'Чтобы добавить связь нажмите кнопку "создать"',
      create: "Создать связь",
      delete: 'Удалить машину %{carNumber} из маршрута "%{routeName}" ?',
    },
    statistics: {
      name: "Статистика",
      fields: {
        index: "#",
        routeNumber: "Номер Маршрута",
        routeName: "Название Маршрута",
        createdAt: "Дата",
        tailNumber: "Бортовой номер машины",
        driverFullName: "Водитель",
        currentShift: "Перевезено пассажиров",
        dayRevenueAmount: "Дневная выручка",
        dateStart: "Время с:",
        dateEnd: "Время по:",
      },
      summary: {
        totalPassengers: "Перевезено оплаченных:",
        totalAmount: "Приход ДС:",
      },
      empty: "Нет статистики",
    },
    "driver-profile": {
      name: "Профиль",
      fields: {
        email: "Электронный адрес",
        fullName: "ФИО",
        personalNumber: "Табельный номер",
        password: "Пароль",
      },
    },
    "carrier-beacons": {
      name: "Бикон  |||| Биконы",
      fields: {
        uid: "ID Beacon",
        major: "Major",
        minor: "Minor",
        macAddress: "Mac address",
        carrierCompanyName: "Перевозчик",
        tailNumber: "Бортовой Номер",
        vehicleNumber: "Номер Транспортного Средства",
      },
      create: "Добавить бикон",
    },
    "admin-beacons": {
      name: "Бикон  |||| Биконы",
      fields: {
        uid: "ID Beacon",
        major: "Major",
        minor: "Minor",
        macAddress: "Mac address",
        carrierCompanyName: "Перевозчик",
      },
      create: "Добавить бикон",
    },
  },
};
