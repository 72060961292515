import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Field } from "react-final-form";
import { makeValidate, Select, TextField } from "mui-rff";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import clsx from "clsx";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import useTitle from "../../../hooks/useTitle.js";
import OrangeButton from "../../../SharedComponents/OrangeButton.jsx";
import ReactFinalForm from "../../../SharedComponents/ReactFinalForm.jsx";
import { useRegistrationContext } from "../../../Providers/Registration/registrationProvider.jsx";
import useCities from "../../../hooks/useCities.js";
import mainStyles from "../../../Assets/js/mainStyles.js";

const schema = Yup.object().shape({
  email: Yup.string().email("Неверный Email"),
});

const validate = makeValidate(schema);

const useStyles = makeStyles((theme) => ({
  municipalityRoot: {
    backgroundColor: "#F5F5F5",
  },
  header: {
    fontWeight: 700,
    fontStyle: "italic",
    margin: "5vh 0 7vh",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  form: {
    //width: "30%",
    //minWidth: 422,
  },
  emailHint: {
    marginBottom: 20,
  },
}));

const useStylesMain = makeStyles(mainStyles);

const MunicipalityRegistration = () => {
  const classes = useStyles();
  const classesMain = useStylesMain();
  const history = useHistory();
  const { setRegistrationData } = useRegistrationContext();
  const cities = useCities();

  useTitle("Квиток просто - Ввод регистрационных данных");

  const onDataSubmit = useCallback(async (formValues) => {
    setRegistrationData(formValues);
    history.push("/registration/final");
  }, []);

  return (
    <Box
      className={classes.municipalityRoot}
      minHeight="calc(100vh - 48px)"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography className={classes.header} variant="h4">
        Данные для регистрации
      </Typography>
      <ReactFinalForm onSubmit={onDataSubmit} validate={validate} formClassName={classes.form}>
        <Field name="role" component="input" type="hidden" defaultValue={"municipality"} />
        <Box className={classesMain.inputFields} display="flex" flexDirection="column" borderRadius="30px" padding="60px">
          <Select name="cityId" label="Город" variant="outlined" required>
            {cities.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <TextField className="email-field" name="email" label="Email" variant="outlined" required />
          <Typography className={classes.emailHint} variant="body2">
            *на эту почту вам прийдёт письмо с подтверждением
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" margin="30px 0">
          <OrangeButton className={classesMain.button} onClick={() => history.goBack()}>
            Назад
          </OrangeButton>
          <Button className={clsx(classesMain.button, classesMain.primaryBtn)} type="submit">
            Далее
          </Button>
        </Box>
      </ReactFinalForm>
    </Box>
  );
};

export default MunicipalityRegistration;
