import React from "react";
import { Show, SimpleShowLayout, TextField } from "react-admin";
import MySimpleBreadcrumbs from "../../../../SharedComponents/MySimpleBreadcrumbs.jsx";

/*eslint-disable react/prop-types*/
const ShowCar = (props) => (
  <Show {...props} title={<MySimpleBreadcrumbs resource={props.resource} />}>
    <SimpleShowLayout>
      <TextField source="beaconUid" />
      <TextField source="tailNumber" />
      <TextField source="vehicleNumber" />
      <TextField source="model" />
      <TextField source="capacity" />
    </SimpleShowLayout>
  </Show>
);

export default ShowCar;
