import React, { useCallback, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeValidate, TextField } from "mui-rff";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import SettingsBackupRestoreRoundedIcon from "@material-ui/icons/SettingsBackupRestoreRounded";
import useTitle from "../../../hooks/useTitle.js";
import ReactFinalForm from "../../../SharedComponents/ReactFinalForm.jsx";
import { useAlert } from "../../../Providers/Common/AlertProvider.jsx";
import { post } from "../../../Utils/NetworkUtils.js";
import { API_URL } from "../../../Constants.js";
import { useSnackbar } from "../../../Providers/Common/snackbarProvider.jsx";
import LoadingButton from "../../../SharedComponents/LoadingButton.jsx";

const schema = Yup.object().shape({
  email: Yup.string().email("Неверный Email"),
});

const validate = makeValidate(schema);

const useStyles = makeStyles({
  header: {
    marginBottom: 40,
    textAlign: "center",
  },
  form: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
    },
    "& .MuiFormControl-root": {
      marginBottom: 30,
    },
  },
  submitButton: {
    marginBottom: 30,
    marginTop: 30,
    alignSelf: "center",
  },
});

const RestorePassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const { alert } = useAlert();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);

  useTitle("Портал “Квиток просто” - Вход");

  const onDataSubmit = useCallback(async (formValues) => {
    setLoading(true);
    try {
      await post(`${API_URL}/forgot-pass`, { email: formValues.email });
      setLoading(false);
      await alert("Запрос на восстановление пароля успешно отправлен");
      history.push("/login");
    } catch (e) {
      setLoading(false);
      snackbar.error(e.message);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box bgcolor="#F5F5F5" minHeight="100vh" display="flex" alignItems="center" justifyContent="center">
      <Box bgcolor="#ECECEC" padding="60px" paddingBottom="10px" display="flex" flexDirection="column">
        <Typography className={classes.header} variant="body2">
          Забыли пароль?
        </Typography>
        <ReactFinalForm onSubmit={onDataSubmit} validate={validate} formClassName={classes.form}>
          <Box display="flex" flexDirection="column">
            <TextField name="email" label="Email" variant="outlined" fullWidth={false} required />
            <LoadingButton
              className={classes.submitButton}
              loading={loading}
              startIcon={<SettingsBackupRestoreRoundedIcon />}
              size="large"
              type="submit"
              variant="contained"
              color="primary"
            >
              ВОССТАНОВИТЬ
            </LoadingButton>
          </Box>
        </ReactFinalForm>
      </Box>
    </Box>
  );
};

export default RestorePassword;
