import React from "react";
import {Create, SimpleForm, TextInput, required, NumberInput, ReferenceInput, SelectInput} from "react-admin";
const validateRequired = required();

/*eslint-disable react/prop-types*/
const SpecialSelect = (props) => {
  return (
    <SelectInput
      {...props}
      optionText="companyName"
    />
  );
};

const CreateBeacon = (props) => {
  return (
    <Create {...props} title="resources.admin-beacons.create">
      <SimpleForm redirect="list">
        <TextInput source="uid" validate={validateRequired} />
        <NumberInput source="major" step={1} min={0} validate={validateRequired} />
        <NumberInput source="minor" step={1} min={0} validate={validateRequired} />
        <TextInput source="macAddress" validate={validateRequired} />
        <ReferenceInput source="carrierId" reference="carriers">
          <SpecialSelect />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default CreateBeacon;
