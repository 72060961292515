import React, { useCallback } from "react";
import {
  Edit,
  email,
  PasswordInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useRedirect,
  useRefresh,
} from "react-admin";
import { makeStyles, MuiThemeProvider } from "@material-ui/core";
import { passwordMatch } from "../../../../Utils/Validators.js";
import MySimpleBreadcrumbs from "../../../../SharedComponents/MySimpleBreadcrumbs.jsx";
import adminTheme from "../../adminTheme.js";
import { overrideTheme } from "../../../../Utils/theming.js";
import { ROLE_CHOICES } from "../../../../Utils/Constants.js";
import MyStandardEditToolbar from "../../../../SharedComponents/MyStandardEditToolbar.jsx";

const validateRequired = required();
const validateEmail = email();

const useStyles = makeStyles({
  editRoot: {
    maxWidth: "unset",
  },
});

const editTheme = overrideTheme(adminTheme, {
  RaEdit: {
    card: {
      maxWidth: 1072,
      margin: "0 20px 0 0",
    },
    main: {
      alignItems: "flex-start",
    },
  },
  MuiCardContent: {
    root: {
      display: "flex",
      flexWrap: "wrap",
      "&>*": {
        marginRight: 20,
      },
    },
  },
});

const EditCarrier = (props) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onDataSaved = useCallback(() => {
    redirect("/carriers");
    refresh();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MuiThemeProvider theme={editTheme}>
      <Edit
        {...props}
        className={classes.editRoot}
        undoable={false}
        onSuccess={onDataSaved}
        // eslint-disable-next-line react/prop-types
        title={<MySimpleBreadcrumbs resource={props.resource} />}
      >
        <SimpleForm toolbar={<MyStandardEditToolbar nameField="companyName" />} validate={passwordMatch}>
          <SelectInput source="role" choices={ROLE_CHOICES.admins} validate={validateRequired} />
          <TextInput source="companyName" validate={validateRequired} />
          <TextInput source="email" autoComplete="off" validate={[validateRequired, validateEmail]} />
          <PasswordInput source="password" autoComplete="new-password" />
          <PasswordInput source="passwordRepeat" autoComplete="new-password" />
          <TextInput source="fullName" validate={validateRequired} />
          <TextInput source="phone" validate={validateRequired} />
          <TextInput source="taxNumber" validate={validateRequired} />
          <TextInput source="otherDetail" />
        </SimpleForm>
      </Edit>
    </MuiThemeProvider>
  );
};

export default EditCarrier;
