import React from "react";
import {Create, SimpleForm, TextInput, required, NumberInput, maxLength, ReferenceInput, SelectInput} from "react-admin";
const validateRequired = required();

/*eslint-disable react/prop-types*/
const SpecialSelect = (props) => {
  return (
    <SelectInput
      {...props}
      optionText="uid"
    />
  );
};

const CreateCar = (props) => {
  return (
    <Create {...props} title="resources.cars.create">
      <SimpleForm redirect="list">
        <ReferenceInput source="beaconId" reference="carrier-car-beacons" validate={validateRequired}>
          <SpecialSelect />
        </ReferenceInput>
        <TextInput source="tailNumber" validate={validateRequired} />
        <TextInput source="vehicleNumber" validate={[validateRequired, maxLength(8)]} />
        <TextInput source="model" validate={validateRequired} />
        <NumberInput source="capacity" step={1} min={0} validate={[validateRequired]} />
      </SimpleForm>
    </Create>
  );
};

export default CreateCar;
