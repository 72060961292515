import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  usePermissions,
  NumberInput,
} from "react-admin";

const validateRequired = required();

const CreateRoute = (props) => {
  const perms = usePermissions();
  /** @type Permissions */
  const permissions = perms.permissions;

  if (!permissions) {
    return null;
  }

  return (
    <Create {...props}>
      <SimpleForm
        redirect="list"
        initialValues={{
          adminId: permissions.user.id,
        }}
      >
        <TextInput source="routeName" validate={validateRequired} />
        <TextInput source="startPoint" validate={validateRequired} />
        <TextInput source="endPoint" validate={validateRequired} />
        <TextInput source="routeNumber" validate={validateRequired} />
        <NumberInput source="duration" step={1} min={1} validate={validateRequired} />
        <NumberInput source="distance" step={0.1} min={1} validate={validateRequired} />
        <NumberInput source="price" step={0.1} min={0} validate={validateRequired} />
        <TextInput source="note" />
      </SimpleForm>
    </Create>
  );
};

export default CreateRoute;
