import React, { useEffect, useState } from "react";
import { Datagrid, List, TextField, Filter, useListContext, useTranslate, useLocale } from "react-admin";
import { makeStyles, MuiThemeProvider } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import XLSX from "xlsx";
import ReferenceFilterByName from "../../../../SharedComponents/ReferenceFilterByName.jsx";
import MaterialDateTimeInput from "../../../../SharedComponents/MaterialDateTimeInput.jsx";
import {dateToString, isoDateToDefaultDate} from "../../../../Utils/DateUtils.js";
import MyPagination from "../../../../SharedComponents/MyPagination.jsx";
import { overrideTheme } from "../../../../Utils/theming.js";
import adminTheme from "../../adminTheme.js";

const filterTheme = overrideTheme(adminTheme, {
  MuiInputBase: {
    root: {
      minWidth: 200,
    },
  },
  MuiFilledInput: {
    adornedEnd: {
      paddingRight: 0,
    },
  },
});

const useSummaryStyles = makeStyles({
  root: {
    marginTop: 10,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  summaryValue: {
    fontSize: 14,
    fontWeight: 300,
    marginLeft: 10,
  },
});

/*eslint-disable react/prop-types*/
export const DateMonthTextField = (props) => {
  const {record} = props;
  return (<Typography>{dateToString(new Date(record.createdAt), "dd.MM.yyyy")}</Typography>);
}

const Filters = (props) => {
  return (
    <MuiThemeProvider theme={filterTheme}>
      <Filter {...props} >
        <ReferenceFilterByName reference="routes" source="routeNumber" />
        <ReferenceFilterByName reference="routes" source="routeName" />
        {/* eslint-disable-next-line react/prop-types */}
        <MaterialDateTimeInput source="dateStart" alwaysOn resource={props.resource} />
        {/* eslint-disable-next-line react/prop-types */}
        <MaterialDateTimeInput source="dateEnd" alwaysOn resource={props.resource} />
        <ReferenceFilterByName minWidth={250} reference="cars" source="tailNumber" />
      </Filter>
    </MuiThemeProvider>
  );
};

const Summary = () => {
  const classes = useSummaryStyles();
  const listContext = useListContext();
  const [summary, setSummary] = useState(/** @type Summary */ {});
  const t = useTranslate();

  useEffect(() => {
    /** @type StatisticsItem[] */
    const data = Object.values(listContext.data);
    if (data && data.length > 0) {
      setSummary(data[0].summary);
    } else {
      setSummary({ passengers: 0, payments: 0 });
    }
  }, [listContext.data]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="body1" component="span" className={classes.title}>
          {t("resources.statistics.summary.totalPassengers")}
        </Typography>
        <Typography variant="body1" component="span" className={classes.summaryValue}>
          {summary.passengers || 0}
        </Typography>
        <br />
        <Typography variant="body1" component="span" className={classes.title}>
          {t("resources.statistics.summary.totalAmount")}
        </Typography>
        <Typography variant="body1" component="span" className={classes.summaryValue}>
          {(summary.payments || 0).toFixed(2)} {t("common.currency")}
        </Typography>
      </CardContent>
    </Card>
  );
};

const exporter = (t, locale) => {
  /** @param {StatisticsItem[]} data */
  const f = function (data) {
    const dataForExport = data.map((item) => {
      item.createdAt = isoDateToDefaultDate(item.createdAt, locale);
      //eslint-disable-next-line unused-imports/no-unused-vars
      const { id, summary, revenuePerShift, status, closed, updatedAt, vehicleNumber, beaconUid, ...itemForExport } = item;
      return itemForExport;
    });
    const headers = Object.keys(dataForExport[0]).map((key) => t(`resources.statistics.fields.${key}`));
    const ws = XLSX.utils.aoa_to_sheet([headers]);
    XLSX.utils.sheet_add_json(ws, dataForExport, { origin: "A2", skipHeader: true });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Statistics");
    XLSX.writeFile(wb, "Statistics.xlsx");
  };
  return f;
};

const listTheme = overrideTheme(adminTheme, {
  RaList: {
    main: {
      marginTop: 20,
      flexDirection: "column",
    },
  },
});

export const ListStatistics = (props) => {
  const t = useTranslate();
  const locale = useLocale();
console.log(props)
  return (
    <MuiThemeProvider theme={listTheme}>
      <List
        {...props}
        hasCreate={false}
        exporter={exporter(t, locale)}
        bulkActionButtons={false}
        filters={<Filters />}
        aside={<Summary />}
        perPage={75}
        pagination={<MyPagination />}
      >
        <Datagrid>
          <TextField source="routeNumber" sortable={false} />
          <TextField source="routeName" sortable={false} />
          <DateMonthTextField source="createdAt" sortable={false}/>
          <TextField source="tailNumber" sortable={false} />
          <TextField source="driverFullName" sortable={false} />
          <TextField source="currentShift" sortable={false} />
          <TextField source="dayRevenueAmount" sortable={false} />
        </Datagrid>
      </List>
    </MuiThemeProvider>
  );
};
