export const ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN";
export const ROLE_CARRIER = "ROLE_CARRIER";
export const ROLE_MUNICIPALITY = "ROLE_MUNICIPALITY";
export const ROLE_OPERATOR = "ROLE_OPERATOR";
export const ROLE_MECHANIC = "ROLE_MECHANIC";
export const ROLE_REPORTING_GROUP = "ROLE_REPORTING_GROUP";
export const ROLE_DRIVER = "ROLE_DRIVER";
export const ROLE_USER = "ROLE_USER";
export const ROLE_DISPATCHER = "ROLE_DISPATCHER";

export const ROLE_CHOICES = {
  superAdmin: [{ id: ROLE_SUPER_ADMIN, name: "roles.ROLE_SUPER_ADMIN" }],
  admins: [
    { id: ROLE_CARRIER, name: "roles.ROLE_CARRIER" },
    { id: ROLE_MUNICIPALITY, name: "roles.ROLE_MUNICIPALITY" },
  ],
  employees: [
    { id: ROLE_OPERATOR, name: "roles.ROLE_OPERATOR" },
    { id: ROLE_DISPATCHER, name: "roles.ROLE_DISPATCHER" },
    { id: ROLE_MECHANIC, name: "roles.ROLE_MECHANIC" },
    { id: ROLE_REPORTING_GROUP, name: "roles.ROLE_REPORTING_GROUP" },
  ],
  others: [
    { id: ROLE_DRIVER, name: "roles.ROLE_DRIVER" },
    { id: ROLE_USER, name: "roles.ROLE_USER" },
  ],
};
