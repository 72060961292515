import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  usePermissions,
  NumberInput,
} from "react-admin";
import MySimpleBreadcrumbs from "../../../../SharedComponents/MySimpleBreadcrumbs.jsx";
import MyStandardEditToolbarWithList from "../../../../SharedComponents/MyStandardEditToolbarWithList.jsx";

const validateRequired = required();

/*eslint-disable react/prop-types*/
const EditRoute = (props) => {
  const perms = usePermissions();
  /** @type Permissions */
  const permissions = perms.permissions;

  if (!permissions) {
    return null;
  }

  return (
    <Edit {...props} undoable={false} title={<MySimpleBreadcrumbs resource={props.resource} />}>
      <SimpleForm
        toolbar={<MyStandardEditToolbarWithList nameField="routeName" />}
        redirect="list"
        initialValues={{
          adminId: permissions.user.id,
        }}
      >
        <TextInput source="routeName" validate={validateRequired} />
        <TextInput source="startPoint" validate={validateRequired} />
        <TextInput source="endPoint" validate={validateRequired} />
        <TextInput source="routeNumber" validate={validateRequired} />
        <NumberInput source="duration" step={1} min={1} validate={validateRequired} />
        <NumberInput source="distance" step={0.1} min={1} validate={validateRequired} />
        <NumberInput source="price" step={0.1} min={0} validate={validateRequired} />
        <TextInput source="note" />
      </SimpleForm>
    </Edit>
  );
};

export default EditRoute;
