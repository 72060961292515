import React, { useCallback, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  useTranslate,
  useDataProvider,
  useRefresh,
  useRedirect,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import { useFormState } from "react-final-form";
import { useSnackbar } from "../../../../Providers/Common/snackbarProvider.jsx";
import MySimpleBreadcrumbs from "../../../../SharedComponents/MySimpleBreadcrumbs.jsx";
import RedToolbarButton from "../../../../SharedComponents/RedToolbarButton.jsx";
import ToolbarButton from "../../../../SharedComponents/ToolbarButton.jsx";

const useStyles = makeStyles((theme) => ({
  leftSpace: {
    margin: `0 0 0 ${theme.spacing(3)}px`,
  },
}));


const SubscriptionRequestToolbar = (props) => {
  const t = useTranslate();
  const classes = useStyles();
  const formState = useFormState();
  const { put } = useDataProvider();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const snackbar = useSnackbar();

  const acceptHandler = useCallback(async () => {
    /** @type SubscriptionRequest */
    const data = { ...formState.values };
    delete data.id;
    data.status = true;
    try {
      await put(`subscription-requests/approve/${formState.values.id}`, data);
      redirect("/subscription-requests");
      refresh();
    } catch (e) {
      snackbar.error(e.message);
    }
  }, [formState.values]);

  const rejectHandler = useCallback(async () => {
    /** @type SubscriptionRequest */
    const data = { ...formState.values };
    delete data.id;

    try {
      await put(`subscription-requests/reject/${formState.values.id}`, data);
      redirect("/subscription-requests");
      refresh();
    } catch (e) {
      snackbar.error(e.message);
    }
  }, [formState.values]);

  return (
    <>
      <Toolbar {...props}>
        <ToolbarButton variant="contained" color="primary" onClick={acceptHandler}>
          {t("resources.subscription-requests.accept")}
        </ToolbarButton>
        <RedToolbarButton className={classes.leftSpace} variant="contained" onClick={rejectHandler}>
          {t("resources.subscription-requests.decline")}
        </RedToolbarButton>
      </Toolbar>
    </>
  );
};

const ProtectedForm = (props) => {
  const { record } = props;
  const redirect = useRedirect();

  useEffect(() => {
    if (record && record.status === true) {
      redirect("/subscription-requests");
    }
  }, [record]);

  if (!record) {
    return null;
  }

  return <SimpleForm {...props} />;
};

/*eslint-disable react/prop-types*/
const EditSubscriptionRequest = (props) => {
  return (
    <Edit {...props} undoable={false} title={<MySimpleBreadcrumbs resource={props.resource} />}>
      <ProtectedForm toolbar={<SubscriptionRequestToolbar />}>
        <TextInput source="companyName" disabled variant="outlined" />
        <TextInput source="taxNumber" disabled variant="outlined" />
        <TextInput source="paymentId" disabled variant="outlined" />
        <TextInput source="amount" disabled variant="outlined" />
      </ProtectedForm>
    </Edit>
  );
};

export default EditSubscriptionRequest;
