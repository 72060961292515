import React from "react";
import { Datagrid, List, TextField } from "react-admin";
import MyPagination from "../../../../SharedComponents/MyPagination.jsx";
import ListTextFilter from "../../../../SharedComponents/ListTextFilter.jsx";
import TextCreatedField from "../../../../SharedComponents/TextCreatedField.jsx";

export const ListCarrierPayments = (props) => {

  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      perPage={25}
      pagination={<MyPagination />}
      filters={<ListTextFilter />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="paymentId" />
        <TextField source="uid" />
        <TextField source="companyName" />
        <TextField source="tailNumber" />
        <TextField source="vehicleNumber" />
        <TextCreatedField label="Дата оплаты"/>
      </Datagrid>
    </List>
  );
};
