import { useState } from "react";
import { useSnackbar } from "../Providers/Common/snackbarProvider.jsx";
import useEffectAsync from "./useEffectAsync.js";
import { get } from "../Utils/NetworkUtils.js";
import { API_URL } from "../Constants.js";

/**
 * @returns {City[]}
 */
export default function () {
  const snackbar = useSnackbar();
  const [cities, setCities] = useState(/** @type City[] */ []);

  useEffectAsync(async () => {
    try {
      const resp = await get(`${API_URL}/cities`);
      if (resp.data) {
        setCities(resp.data.items);
      } else {
        snackbar.error(`${resp.error.code}: ${resp.error.text}`);
      }
    } catch (e) {
      snackbar.error(e.toString());
    }
  }, []);

  return cities;
}
