import React from "react";
import { Edit, SimpleForm, required, ReferenceInput, useTranslate } from "react-admin";
import MySimpleBreadcrumbs from "../../../../SharedComponents/MySimpleBreadcrumbs.jsx";
import { CarSelectWithInfo, DriverSelectWithInfo, RouteSelectWithInfo, useCreateStyles } from "./CreateConnection.jsx";
import MyStandardEditToolbarWithList from "../../../../SharedComponents/MyStandardEditToolbarWithList.jsx";

const validateRequired = required();

/*eslint-disable react/prop-types*/
const EditConnection = (props) => {
  const classes = useCreateStyles();
  const t = useTranslate();

  return (
    <Edit
      {...props}
      className={classes.root}
      undoable={false}
      title={<MySimpleBreadcrumbs resource={props.resource} />}
    >
      <SimpleForm
        redirect="list"
        toolbar={
          <MyStandardEditToolbarWithList
            customDeleteConfirmText={
              /** @param {Connection} record */
              (record) =>
                `${t("resources.connections.delete", { carNumber: record.vehicleNumber, routeName: record.routeName })}`
            }
          />
        }
      >
        <ReferenceInput source="carId" reference="cars" validate={validateRequired}>
          <CarSelectWithInfo />
        </ReferenceInput>
        <ReferenceInput source="driverId" reference="drivers" validate={validateRequired}>
          <DriverSelectWithInfo />
        </ReferenceInput>
        <ReferenceInput source="routeId" reference="routes" validate={validateRequired}>
          <RouteSelectWithInfo />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default EditConnection;
