import React, { useEffect } from "react";
import * as PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLocale, useSetLocale } from "react-admin";
import clsx from "clsx";
import flag_ru from "../../../Assets/images/flags/flag_russia.svg";
import flag_uk from "../../../Assets/images/flags/flag_ukraine.svg";

const useStyles = (flexDirection) =>
  makeStyles((theme) => ({
    selectors: {
      display: "flex",
      justifyContent: "center",
      flexDirection: flexDirection === "auto" ? "column" : flexDirection,
    },
    sidebarOpen: {
      flexDirection: "row",
    },
    button: {
      padding: theme.spacing(1),
    },
    icon: {
      width: "24px",
      height: "24px",
    },
  }));

const locales = [
  {
    locale: "ru",
    icon: flag_ru,
    name: "Russian",
  },
  {
    locale: "uk",
    icon: flag_uk,
    name: "Ukrainian",
  },
];

const LocaleSelector = (props) => {
  const { flexDirection } = props;
  const classes = useStyles(flexDirection)();
  const locale = useLocale();
  const setLocale = useSetLocale();
  const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);

  useEffect(() => {
    let locale = localStorage.getItem("locale");

    if (!locale) {
      locale = "uk";
      localStorage.setItem("locale", locale);
    }

    setLocale(locale).catch();
  }, []);

  return (
    <div className={clsx(classes.selectors, { [classes.sidebarOpen]: sidebarOpen && flexDirection === "auto" })}>
      {locales.map((item) => (
        <IconButton
          key={item.locale}
          className={classes.button}
          onClick={() => {
            setLocale(item.locale).catch();
            localStorage.setItem("locale", item.locale);
          }}
        >
          <img
            className={classes.icon}
            style={{ opacity: locale === item.locale ? 1.0 : 0.3 }}
            src={item.icon}
            alt={item.name}
          />
        </IconButton>
      ))}
    </div>
  );
};

LocaleSelector.propTypes = {
  flexDirection: PropTypes.oneOf(["auto", "row", "column"]),
};

LocaleSelector.defaultProps = {
  flexDirection: "auto",
};

export default LocaleSelector;
