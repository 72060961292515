import React, { useCallback } from "react";
import { TextField } from "mui-rff";
import { Button, Box, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import useTitle from "../../../hooks/useTitle.js";
import OrangeButton from "../../../SharedComponents/OrangeButton.jsx";
import ReactFinalForm from "../../../SharedComponents/ReactFinalForm.jsx";
import { useRegistrationContext } from "../../../Providers/Registration/registrationProvider.jsx";
import { post } from "../../../Utils/NetworkUtils.js";
import { API_URL } from "../../../Constants.js";
import { useSnackbar } from "../../../Providers/Common/snackbarProvider.jsx";
import mainStyles from "../../../Assets/js/mainStyles.js";

const useStyles = makeStyles((theme) => ({
  finalRoot: {
    backgroundColor: "#F5F5F5",
  },
  header: {
    fontWeight: 700,
    fontStyle: "italic",
    margin: "5vh 0 7vh",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
    textAlign: "center",
  },
  form: {},
  sendBtn: {
    marginLeft: 30,
    [theme.breakpoints.down("md")]: {
      marginLeft: 10,
    },
  },
}));

const useStylesMain = makeStyles(mainStyles);

const FinalRegistrationStep = () => {
  const classes = useStyles();
  const classesMain = useStylesMain();
  const history = useHistory();
  const snackbar = useSnackbar();
  const { mergeWithData } = useRegistrationContext();

  useTitle("Квиток просто - Установка пароля");

  const onDataSubmit = useCallback(async (formValues) => {
    const registrationData = mergeWithData(formValues);
    try {
      const response = await post(`${API_URL}/register`, registrationData);
      if (response.data) {
        history.push("/registration/registration-success");
      } else {
        snackbar.error(`${response.error.code}: ${response.error.text}`);
      }
    } catch (e) {
      snackbar.error(e.toString());
    }
  }, []);

  return (
    <Box
      className={classes.finalRoot}
      minHeight="calc(100vh - 48px)"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography className={classes.header} variant="h4">
        Завершение регистрации.
        <br />
        Введите надежный пароль
      </Typography>
      <ReactFinalForm
        onSubmit={onDataSubmit}
        formClassName={classes.form}
        validate={(values) => {
          const errors = {};
          if (values.confirm !== values.password) {
            errors.confirm = "Пароли должны совпадать";
          }
          return errors;
        }}
      >
        <Box className={classesMain.inputFields}>
          <TextField name="password" label="Пароль" type="password" variant="outlined" required />
          <TextField name="confirm" label="Повторите пароль" type="password" variant="outlined" required />
        </Box>
        <Box display="flex" justifyContent="space-between" margin="30px 0">
          <OrangeButton className={classesMain.button} onClick={() => history.goBack()}>
            Назад
          </OrangeButton>
          <Button className={clsx(classesMain.button, classesMain.primaryBtn, classes.sendBtn)} type="submit">
            отправить на верификацию
          </Button>
        </Box>
      </ReactFinalForm>
    </Box>
  );
};

export default FinalRegistrationStep;
