import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import bgImage from "../../../Assets/bus_interior.png";
import logo from "../../../Assets/logo.svg";
import useTitle from "../../../hooks/useTitle.js";
import mainStyles from "../../../Assets/js/mainStyles.js";

const useStyles = makeStyles((theme) => ({
  blocksWrapper: {
    display: "flex",
    minHeight: "calc(100vh - 48px)",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  blocks: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  rightBlock: {
    backgroundImage: `url(${bgImage})`,
    padding: 20,
  },
  header: {
    fontWeight: 700,
    fontStyle: "italic",
    marginBottom: 80,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      marginBottom: 30,
    },
  },
  regularText: {
    fontStyle: "italic",
    fontWeight: 500,
    marginBottom: 80,
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
      marginBottom: 30,
    },
  },
  logo: {
    [theme.breakpoints.down("md")]: {
      width: 100,
      height: 100,
    },
  },
}));

const useStylesMain = makeStyles(mainStyles);

const StartPage = () => {
  const classes = useStyles();
  const classesMain = useStylesMain();
  const history = useHistory();

  useTitle(`Добро пожаловать на портал “Квиток просто”`);

  return (
    <Box className={classes.blocksWrapper}>
      <Box className={classes.blocks} padding="10%">
        <Typography className={classes.header} variant="h4">
          Добро пожаловать на портал “Квиток просто”
        </Typography>
        <Typography className={classes.regularText} variant="h5">
          Чтобы начать пользоваться системой пожалуйста зарегистрируйтесь.
        </Typography>
        <Typography className={classes.regularText} variant="h5">
          Это займёт всего несколько минут!
        </Typography>
        <Button className={clsx(classesMain.button, classesMain.primaryBtn)} onClick={() => history.push("/registration/role")}>
          НАЧАТЬ РЕГИСТРАЦИЮ
        </Button>
      </Box>
      <Box
        className={clsx(classes.blocks, classes.rightBlock)}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <img className={classes.logo} src={logo} />
      </Box>
    </Box>
  );
};

export default StartPage;
